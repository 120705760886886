import { useEffect } from "react";

export const useAnimateOnScroll = (ref, selector, animationClasses, isOnce) => {
  useEffect(() => {
    if (ref.current) {
      const elementsToAnimate = ref.current.querySelectorAll(selector);

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.remove("opacity-0");
              entry.target.classList.add(...animationClasses, "opacity-1");
            } else {
              if (isOnce) {
                return;
              }
              entry.target.classList.remove(...animationClasses, "opacity-1");
              entry.target.classList.add("opacity-0");
            }
          });
        },
        {
          rootMargin: "0px",
          threshold: 0.1,
        }
      );

      elementsToAnimate.forEach((element) => observer.observe(element));

      return () => observer.disconnect();
    }
  }, [ref, selector, animationClasses, isOnce]); // Dependencies array includes the parameters so the effect can adjust to changes
};
