import React, { useRef } from "react";
import { useAnimateOnScroll } from "./hooks/useAnimateOnScroll";
import "./App.css";

function App() {
  const containerRef = useRef(null);
  const profileRef = useRef(null);
  useAnimateOnScroll(containerRef, ".on-animate-card", [
    "motion-safe:animate-fade-up",
  ]);
  useAnimateOnScroll(
    profileRef,
    ".on-animate-profile-left",
    ["motion-safe:animate-fade-right"],
    true
  );
  useAnimateOnScroll(
    profileRef,
    ".on-animate-profile-right",
    ["motion-safe:animate-fade-left"],
    true
  );

  return (
    <>
      <header className="border-b-2 bg-white sticky top-0 z-10">
        <section className="max-w-6xl mx-auto p-2 pr-8 px-6  bg-white flex justify-between items-center">
          <a href="/">
            <img
              alt="logo"
              src={require("./assets/pmg-logo.png")}
              className="w-[300px] object-contain"
            />
          </a>
          <div>
            <nav
              className="hidden md:block font-display mt-2"
              aria-label="main"
            >
              <ul className="flex space-x-10">
                <li>
                  <a
                    href="#projects"
                    className="text-md font-semibold text-gray-500 hover:opacity-60 hover:text-primary-pink-200"
                  >
                    Projects
                  </a>
                </li>
                <li>
                  <a
                    href="#goals"
                    className="text-md font-semibold text-gray-500 hover:opacity-60 hover:text-primary-pink-200"
                  >
                    Goals
                  </a>
                </li>
                <li>
                  <a
                    href="#developers"
                    className="text-md font-semibold text-gray-500 hover:opacity-60 hover:text-primary-pink-200"
                  >
                    Developers
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </header>

      <section className="max-w-6xl px-6 mt-4 mb-10 flex flex-col md:flex-row mx-auto items-center font-display">
        <div className="flex-1 flex-col p-4 items-center text-center md:text-left">
          <p className="text-4xl font-medium text-zinc-700 mb-4 flex-row leading-snug">
            Enrich Your Life with Fun, Creative{" "}
            <span className="font-bold text-primary-pink-500">Tools</span> and
            Exciting{" "}
            <span className="font-bold text-primary-mint-500">Games</span>
          </p>
          <p className="font-light text-lg text-zinc-500 mb-4 leading-relaxed">
            We design and develop a wide range of games, including mobile,
            console, board, and card games. Name it, and we make it!
          </p>

          <button className="bg-primary-pink-500 text-white font-semibold text-sm px-4 py-2 mt-4 rounded-3xl min-w-[180px] h-12 hover:opacity-80">
            <a href="#projects">View Projects</a>
          </button>
        </div>

        <div className="flex-1 flex-col p-4 items-center justify-center">
          <img
            alt="main-page"
            src={require("./assets/main-page-graphic.png")}
            className="object-contain animate-fade-in"
          />
        </div>
      </section>

      <hr className="mx-10 xl:mx-auto w-auto max-w-6xl border-t-2 border-gray-200" />

      <section
        id="projects"
        className="max-w-6xl px-6 my-10 mx-auto font-display items-center flex flex-col scroll-mt-24"
      >
        <h2 className="text-3xl font-bold text-zinc-700 mb-4 text-center">
          Our Projects
        </h2>
        <p className="text-md font-light text-zinc-500 mb-4 leading-relaxed text-center md:w-2/3">
          This year, we have just initiated building mobile app projects. These
          apps are designed to improve daily life and provide valuable learning
          opportunities for us.
        </p>

        <ul
          ref={containerRef}
          className="list-none mx-auto my-12 px-10 w-full flex flex-col md:flex-row gap-20 text-zinc-700"
        >
          <li className="on-animate-card flex-1 shadow-lg shadow-primary-pink-50 rounded-2xl bg-white overflow-hidden">
            <div className="p-4 bg-primary-pink-200 flex flex-row justify-center items-center min-h-[70px] gap-4">
              <img
                alt="Mobile Phone"
                className="w-[36px] object-contain"
                src={require("./assets/mobile-pink.png")}
              />
            </div>
            <div className="p-4 min-h-[220px] flex flex-col justify-around">
              <h3 className="text-black text-center font-bold text-xl">
                Chattie
              </h3>
              <p className="text-sm font-light text-center">
                Chat With Friends or with group
              </p>
              <p className="text-sm font-light text-center text-[#F68555]">
                Remake on Sept 2024
              </p>
            </div>
          </li>
          <li className="on-animate-card flex-1 shadow-lg shadow-primary-mint-50 rounded-2xl bg-white overflow-hidden">
            <div className="p-4 bg-primary-mint-200 flex flex-row justify-center items-center min-h-[70px] gap-4">
              <img
                alt="Mobile Phone"
                className="w-[36px] object-contain"
                src={require("./assets/mobile-mint.png")}
              />
              <img
                alt="Desktop Computer"
                className="w-[65px] object-contain"
                src={require("./assets/desktop-mint.png")}
              />
            </div>
            <div className="p-4 min-h-[220px] flex flex-col justify-around">
              <h3 className="text-black text-center font-bold text-xl">
                FinanceTracker
              </h3>
              <p className="text-sm font-light text-center">
                Strictly Track your finance through App
              </p>
              <p className="text-sm font-light text-center text-[#F68555]">
                Finished By Aug 2024
              </p>
            </div>
          </li>
          <li className="on-animate-card flex-1 shadow-lg shadow-primary-pink-50 rounded-2xl bg-white overflow-hidden">
            <div className="p-4 bg-primary-pink-200 flex flex-row justify-center items-center min-h-[70px] gap-4">
              <img
                alt="Mobile Phone"
                className="w-[36px] object-contain"
                src={require("./assets/mobile-pink.png")}
              />
            </div>
            <div className="p-4 min-h-[220px] flex flex-col justify-around">
              <h3 className="text-black text-center font-bold text-xl">
                Aimee
              </h3>
              <p className="text-sm font-light text-center">
                Schedule your task and log your journal
              </p>
              <p className="text-sm font-light text-center text-[#F68555]">
                Paused Development
              </p>
            </div>
          </li>
        </ul>
      </section>

      <hr className="mx-10 xl:mx-auto w-auto max-w-6xl border-t-2 border-gray-200" />

      <section
        id="goals"
        className="max-w-6xl px-6 my-10 mx-auto font-display items-center flex flex-col scroll-mt-24"
      >
        <h2 className="text-3xl font-bold text-zinc-700 mb-4 text-center">
          Team Goals
        </h2>

        <p className="text-md font-light text-zinc-500 mb-12 leading-relaxed text-center md:w-2/3">
          Since we have just started, we have many goals to achieve. We are
          excited to follow our roadmap and bring our vision to life
        </p>

        <img
          alt="Desktop Computer"
          className="w-5/6 object-contain"
          src={require("./assets/road-map-goals.png")}
        />
      </section>

      <hr className="mx-10 xl:mx-auto w-auto max-w-6xl border-t-2 border-gray-200" />

      <section
        ref={profileRef}
        id="developers"
        className="max-w-6xl px-6 my-10 mx-auto font-display items-center flex flex-col scroll-mt-28"
      >
        <h2 className="text-3xl font-bold text-zinc-700 mb-12 text-center">
          Meet the Developers
        </h2>

        <section className="flex flex-col md:flex-row gap-2 px-8 items-center">
          <div className="on-animate-profile-left md:w-1/3 flex-col p-4 items-center">
            <img
              alt="Mervin Developer"
              className="w-[390px] object-contain"
              src={require("./assets/mervin-profile-image.png")}
            />
            <div className="flex flex-row-reverse gap-4 mt-4">
              <button>
                <a href="https://www.linkedin.com/in/mervin-narvaez-85b183188">
                  <img
                    alt="linkedin-icon"
                    className="w-12 object-contain"
                    src={require("./assets/icons/linkedin-icon.png")}
                  />
                </a>
              </button>

              <button>
                <a href="https://github.com/Mervz-Dev">
                  <img
                    alt="github-icon"
                    className="w-12 object-contain"
                    src={require("./assets/icons/github-icon.png")}
                  />
                </a>
              </button>
              <button>
                <a href="https://stackoverflow.com/users/11219378/mervzs">
                  <img
                    alt="stack-overflow-icon"
                    className="w-12 object-contain"
                    src={require("./assets/icons/stack-overflow-icon.png")}
                  />
                </a>
              </button>
              <button>
                <a href="mailto:mervinnarvaez.dev@gmail.com">
                  <img
                    alt="mail-icon"
                    className="w-12 object-contain"
                    src={require("./assets/icons/mail-icon.png")}
                  />
                </a>
              </button>
            </div>
          </div>
          <div className="on-animate-profile-right md:w-2/3 flex-col p-4 items-center md:mt-12">
            <h2 className="text-2xl font-bold text-zinc-700 leading-normal">
              Mervin Narvaez
            </h2>
            <h3 className="text-md font-light text-zinc-700 mb-2 ">
              <span className="font-medium text-primary-pink-500">Mobile</span>{" "}
              and <span className="font-medium text-primary-mint-500">Web</span>{" "}
              Developer
            </h3>

            <hr className="md:w-4/5 max-w-2xl border-t-2 border-gray-200 mb-2" />

            <p className="text-sm font-normal text-zinc-500 mb-4 leading-relaxed md:w-4/5">
              I developed this website. As a flexible software developer, I am
              passionate about creating innovative solutions. I enjoy curry, and
              my favorite color is{" "}
              <span className="font-medium text-primary-pink-500">Pink</span>.
            </p>
          </div>
        </section>

        <section className="flex flex-col md:flex-row-reverse gap-2 px-8 items-center mt-10">
          <div className="on-animate-profile-right md:w-1/3 flex-col p-4 items-center">
            <img
              alt="Arielle Developer"
              className="w-[390px] object-contain"
              src={require("./assets/yeyel-profile-image.png")}
            />
            <div className="flex flex-row gap-4 mt-4">
              <button>
                <a href="https://www.linkedin.com/in/arielle-faye-gorospe-78aa47205">
                  <img
                    alt="linkedin-icon"
                    className="w-12 object-contain"
                    src={require("./assets/icons/linkedin-icon.png")}
                  />
                </a>
              </button>

              <button>
                <a href="https://www.upwork.com/freelancers/~01b776f2440fba6fb2">
                  <img
                    alt="upwork-icon"
                    className="w-12 object-contain"
                    src={require("./assets/icons/upwork-icon.png")}
                  />
                </a>
              </button>
              <button>
                <a href="mailto:ariellefaye.gorospe@gmail.com">
                  <img
                    alt="mail-icon"
                    className="w-12 object-contain"
                    src={require("./assets/icons/mail-icon.png")}
                  />
                </a>
              </button>
            </div>
          </div>
          <div className="on-animate-profile-left md:w-2/3 flex flex-col items-start md:items-end p-4 md:mt-12">
            <h2 className="text-2xl text-right font-bold text-zinc-700 leading-normal">
              Arielle Faye Gorospe
            </h2>
            <h3 className="text-md text-right font-light text-zinc-700 mb-2 ">
              <span className="font-medium text-primary-mint-500">Writer</span>{" "}
              and{" "}
              <span className="font-medium text-primary-pink-500">
                Designer
              </span>
            </h3>

            <hr className="md:w-4/5 max-w-2xl border-t-2 border-gray-200 mb-2" />

            <p className="text-sm font-normal text-zinc-500 mb-4 leading-relaxed md:w-4/5">
              I love to write, and I am also proficient in creating designs. I
              enjoy drinking tea, and my favorite color is{" "}
              <span className="font-medium text-primary-mint-500">Mint</span>.
            </p>
          </div>
        </section>

        <section className="flex flex-col md:flex-row gap-2 px-8 items-center mt-10">
          <div className="on-animate-profile-left md:w-1/3 flex-col p-4 items-center">
            <img
              alt="Minky Developer"
              className="w-[390px] object-contain"
              src={require("./assets/minky-profile-image.png")}
            />
          </div>
          <div className="on-animate-profile-right  md:w-2/3 flex-col p-4 items-center md:mt-12">
            <h2 className="text-2xl font-bold text-zinc-700 leading-normal">
              Minky
            </h2>
            <h3 className="text-md font-light text-zinc-700 mb-2 ">
              <span className="font-medium text-primary-pink-500">Robot</span>{" "}
              and{" "}
              <span className="font-medium text-primary-mint-500">
                Assistant
              </span>
            </h3>
            <hr className="md:w-4/5 max-w-2xl border-t-2 border-gray-200 mb-2" />
            <p className="text-sm font-normal text-zinc-500 mb-4 leading-relaxed md:w-4/5">
              I am the newest addition to this team. I may act like a robot, but
              I can definitely assist you. By the way, I love eating{" "}
              <span className="font-medium text-red-500">wasabi</span>, and my
              favorite color is{" "}
              <span className="font-medium text-primary-pink-500">Pink</span>
              <span className="font-medium text-primary-mint-500">Mint</span>!
            </p>
          </div>
        </section>
      </section>

      <footer className="w-full h-36 p-2 pr-8 px-6 flex justify-center items-center mt-32 bg-gradient-to-r from-primary-pink-500 to-primary-mint-500">
        <p className="text-white text-md">
          © 2024 PinkMintGames. All Rights Reserved.
        </p>
      </footer>
    </>
  );
}

export default App;
